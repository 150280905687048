import axios from "axios";
import { getDeviceInfo } from "../lib/device";
import config from "../config";
import { store } from "../store";
import { setRefreshToken, getLogout } from "../components/loginpage/reducer";
import { saveStorage, getCookie } from "../helpers/storage";

export const apiUrl =
  process.env.NODE_ENV === "development"
    ? config.development.API_BASE_URL
    : config.production.API_BASE_URL;
export const apiPlasgosExpress =
  process.env.NODE_ENV === "development"
    ? config.development.API_EXPEDITION
    : config.production.API_EXPEDITION;
export const hostWeb =
  process.env.NODE_ENV === "development"
    ? config.development.BASE_URL
    : config.production.BASE_URL;
export const hostMobile =
  process.env.NODE_ENV === "development"
    ? config.development.BASE_URL_MOBILE
    : config.production.BASE_URL_MOBILE;
export const sellerWeb =
  process.env.NODE_ENV === "development"
    ? config.development.SELLER_BASE_URL
    : config.production.SELLER_BASE_URL;
export const wcastWeb =
  process.env.NODE_ENV === "development"
    ? config.development.WCAST_BASE_URL
    : config.production.WCAST_BASE_URL;
export const clientIdGoogle =
  process.env.NODE_ENV === "development"
    ? config.development.CLIENT_ID_GOOGLE
    : config.production.CLIENT_ID_GOOGLE;
export const apiGoogleMap =
  process.env.NODE_ENV === "development"
    ? config.development.API_KEY_GOOGLE_MAP
    : config.production.API_KEY_GOOGLE_MAP;
export const Api = axios.create({ baseURL: apiUrl });
export const ApiExpress = axios.create({ baseURL: apiPlasgosExpress });

const refreshAccessToken = async (device_info) => {
  const refreshToken = getCookie("ur_t");
  if (!refreshToken) {
    throw new Error("No refresh token found");
  }

  try {
    const response = await Api.post("/v3/session/refresh", {
      device_info,
      refreshToken,
    });
    const { access_token } = response.data;
    return access_token;
  } catch (error) {
    throw new Error("Refresh token expired or invalid");
  }
};

//sebelum request
Api.interceptors.request.use(
  async (config) => {
    const deviceInfo = await getDeviceInfo();
    // const { token } = store.getState()?.login;
    config.headers["fingerprint"] = deviceInfo.fingerprint;
    config.headers["time-zone"] = deviceInfo.timeZone;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Menangani error 401 untuk access token yang expired
Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.error_code?.toLowerCase() ===
        "access_token_unauthorized"
    ) {
      const originalRequest = error.config;
      // Cek jika token telah kadaluarsa
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const deviceInfo = await getDeviceInfo();
          const newAccessToken = await refreshAccessToken(deviceInfo);
          store.dispatch(setRefreshToken(newAccessToken));
          saveStorage({ accessToken: newAccessToken });
          // Set access token baru untuk permintaan ulang
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          originalRequest.headers["token"] = `${newAccessToken}`;
          originalRequest.headers["fingerprint"] = deviceInfo.fingerprint;
          return axios(originalRequest); // Coba ulang permintaan asli dengan token baru
        } catch (refreshError) {
          // Jika refresh token kadaluarsa, logout
          const token =
            originalRequest.headers["authorization"]?.replace("Bearer ", "") ||
            originalRequest.headers["token"];
          store.dispatch(getLogout({ token }));
        }
      }
    }
    return Promise.reject(error);
  }
);
