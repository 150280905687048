import { Api } from "./api";

export default {
  check: {
    email: (payload) => Api.post(`/v3/register/check/email`, payload),
    phone: (payload) => Api.post(`/v3/register/check/phone`, payload),
  },
  request: {
    email: (payload) => Api.post(`/v3/register/otp/request/email`, payload),
    phone: (payload) => Api.post(`/v3/register/otp/request/phone`, payload),
  },
  verify: (payload) => Api.post(`/v3/register/otp/verify`, payload),
  create: (payload) => Api.post("/v3/register", payload),
};
