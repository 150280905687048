import { Api } from "./api";

export default {
  sessions: (payload) =>
    Api.post("/v3/session", payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    }),
  checkEmailRegister: (payload) =>
    Api.post("/v1/register/check-email", payload),
  createOTPEmailRegister: (payload) =>
    Api.post("/v1/register/create-otp", payload),
  verifiedOTPEmailRegister: (payload) =>
    Api.post("/v1/register/verification-otp", payload),
  register: (payload) => Api.post("/v1/register", payload),
  forgotPassword: (payload) =>
    Api.post("/v1/auth/forgot_password", payload.data),
  lastLogin: (payload) => Api.get(`/v1/log-history/last/${payload.id}`),
  changePassword: (payload) =>
    Api.put(`/v1/auth/change-password/${payload.token}`, payload.data),
  forgot_password: {
    check: (payload) => Api.post("/v2/forgot-password/check", payload),
    request_otp_phone: (payload) =>
      Api.post("/v2/forgot-password/request-otp/phone-number", payload),
    request_otp_email: (payload) =>
      Api.post("/v2/forgot-password/request-otp/email", payload),
    confirm_otp: (payload) =>
      Api.post("/v2/forgot-password/confirm-otp", payload),
    change: (payload) =>
      Api.put("/v2/forgot-password/change-password", payload, {
        headers: {
          token: payload.token,
        },
      }),
  },
};
