import { put, call, delay, takeLatest } from "redux-saga/effects";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";
import { notification } from "../../helpers/notification";

function* watchGetNotificationDiscussions(values) {
  yield put(actions.setIsLoadingGetNotificationDiscussions(true));
  const { payload } = values;
  try {
    const response = yield call(Api.discussion.notification, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getNotificationDiscussionsSuccess(data.notification));
    }
  } catch (error) {
    yield put(actions.setIsLoadingGetNotificationDiscussions(false));
  } finally {
    yield put(actions.setIsLoadingGetNotificationDiscussions(false));
  }
}
function* watchGetProductDiscussions(values) {
  yield put(actions.setIsLoadingGetDiscussions(true));
  const { payload } = values;
  try {
    const response = yield call(Api.discussion.product, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDiscussionsSuccess(data));
    }
  } catch (error) {
    yield put(actions.setIsLoadingGetDiscussions(false));
  } finally {
    yield put(actions.setIsLoadingGetDiscussions(false));
  }
}
function* watchGetDiscussions(values) {
  yield put(actions.setIsLoadingGetDiscussions(true));
  const { payload } = values;
  try {
    const response = yield call(Api.discussion.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDiscussionsSuccess(data));
    }
  } catch (error) {
    yield put(actions.setIsLoadingGetDiscussions(false));
  } finally {
    yield put(actions.setIsLoadingGetDiscussions(false));
  }
}
function* watchAddDiscussion(values) {
  yield put(actions.setIsLoadingAddDiscussion(true));
  const { payload } = values;
  try {
    const response = yield call(Api.discussion.add, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        ...data.data,
        User: payload.user,
        Product: payload.product,
        Comments: [],
      };
      yield put(actions.addDiscussionSuccess(result));
      yield put(actions.setAddedDiscussion(true));
      yield notification.default(data.message);
    }
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield delay(1000);
    yield put(actions.setAddedDiscussion(false));
    yield put(actions.setIsLoadingAddDiscussion(false));
  }
}
function* watchDeleteDiscussion(values) {
  yield put(actions.setIsLoadingDeleteDiscussion(true));
  const { payload } = values;
  try {
    const response = yield call(Api.discussion.delete, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setDeletedDiscussion(true));
      yield put(actions.deleteDiscussionSuccess(payload));
      yield notification.default(data.message);
    }
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield delay(500);
    yield put(actions.setDeletedDiscussion(false));
    yield put(actions.setIsLoadingDeleteDiscussion(false));
  }
}
function* watchAddCommentDiscussion(values) {
  yield put(actions.setIsLoadingAddComment(true));
  const { payload } = values;
  try {
    const response = yield call(Api.discussion.comment.add, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        ...data.data,
        User: payload.user,
      };
      yield put(actions.addCommentSuccess(result));
      yield put(actions.setAddedCommentDiscussion(true));
      yield notification.default(data.message);
    }
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield delay(1000);
    yield put(actions.setAddedCommentDiscussion(false));
    yield put(actions.setIsLoadingAddComment(false));
  }
}
function* watchDeleteCommentDiscussion(values) {
  yield put(actions.setIsLoadingDeleteComment(true));
  const { payload } = values;
  try {
    const response = yield call(Api.discussion.comment.delete, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deleteCommentSuccess(payload));
      yield put(actions.setDeletedCommentDiscussion(true));
      yield notification.default(data.message);
    }
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield delay(1000);
    yield put(actions.setDeletedCommentDiscussion(false));
    yield put(actions.setIsLoadingDeleteComment(false));
  }
}
const saga = [
  takeLatest(types.GET_DISCUSSIONS, watchGetDiscussions),
  takeLatest(types.DELETE_DISCUSSION, watchDeleteDiscussion),
  takeLatest(types.ADD_COMMENT_DISCUSSION, watchAddCommentDiscussion),
  takeLatest(types.DELETE_COMMENT_DISCUSSION, watchDeleteCommentDiscussion),
  takeLatest(types.GET_PRODUCT_DISCUSSIONS, watchGetProductDiscussions),
  takeLatest(types.ADD_DISCUSSION, watchAddDiscussion),
  takeLatest(
    types.GET_NOTIFICATION_DISCUSSIONS,
    watchGetNotificationDiscussions
  ),
];
export default saga;
