import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CFade } from "@coreui/react";

import { sellerWeb, hostWeb, wcastWeb, apiUrl } from "./services/api";
import defaultRoutes, { routes } from "./routes";
import Maintenance from "./views/pages/maintenance";

import "react-loading-skeleton/dist/skeleton.css";
import "./scss/style.scss";

const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Help = React.lazy(() => import("./views/help"));
const Product = React.lazy(() => import("./views/product"));

require("cross-domain-storage/host")([
  {
    origin: hostWeb,
    allowedMethods: ["get", "set", "remove"],
  },
  {
    origin: sellerWeb,
    allowedMethods: ["get"],
  },
  {
    origin: wcastWeb,
    allowedMethods: ["get"],
  },
]);

const App = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const checkServer = async () => {
      try {
        const response = await fetch(`${apiUrl}/status`);
        const data = await response.json();
        setIsOnline(data.online);
      } catch (error) {
        setIsOnline(false);
      }
    };
    checkServer();
    const interval = setInterval(checkServer, 300000); // Cek setiap 5 menit

    return () => clearInterval(interval);
  }, []);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  if (!isOnline) {
    return <Maintenance />;
  }
  return (
    <>
      <BrowserRouter basename="/">
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path="/help"
              name="Help"
              render={(props) => (
                <CFade>
                  <Help {...props} />
                </CFade>
              )}
            />
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
              );
            })}
            {defaultRoutes.map((route, idx) => {
              if (route.name === "Produk") {
                return (
                  <Route
                    key={idx}
                    path="/:subdomain_shop/:subdomain_product"
                    name="Produk"
                    render={(props) => (
                      <CFade>
                        <Product {...props} />
                      </CFade>
                    )}
                  />
                );
              } else {
                return (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <TheLayout {...props} />
                      </CFade>
                    )}
                  />
                );
              }
            })}

            {/* <Route
              path="/"
              name="Home"
              render={(props) => (
                <CFade>
                  <TheLayout {...props} />
                </CFade>
              )}
            /> */}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
