import { Api } from "./api";

export default {
  sessions: (payload) =>
    Api.post("/v3/session", payload, {
      headers: {
        authorization: `Bearer ${payload.token}`,
        fingerprint: payload.device_info?.fingerprint,
      },
    }),
  check: {
    email: (payload) => Api.post(`/v2/login/check-email`, payload),
    phone: (payload) => Api.post(`/v2/login/check-phone`, payload),
  },
  account: (payload) => Api.post("/v3/login", payload),
  facebook: (payload) => Api.post("/v2/login/facebook", payload),
  google: (payload) => Api.post("/v3/login/google", payload),
  last_in: (payload) => Api.get(`/v1/log-history/last/${payload.id}`),
  logout: {
    single: (payload) =>
      Api.post(
        "/v3/logout/single",
        {},
        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        }
      ),
  },
};
